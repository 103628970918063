import * as React from 'react';
import {
    sendPasswordResetMail
} from '../../../api/user';
import {
    Form, Formik
} from 'formik';
import CustomButton from '../../CustomButton/CustomButton';
import FormSubmit from '../../formElements/FormSubmit/FormSubmit';
import EmailInput from '../../formElements/EmailInput/EmailInput';

interface IFormErrors {
    email: string,
}

const PasswordReset = ({
    setShowForgotPassword,
    emailSentCallback,
}) => {
    return <Formik
        initialValues={{
            email: '',
        }}
        validate={values => {
            const errors = {} as IFormErrors;
            if (!values.email) {
                errors.email = 'Pflichtfeld';
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = 'Bitte geben Sie eine gültige E-Mail Adresse an';
            }
            return errors;
        }}
        onSubmit={(
            values, {
                setSubmitting,
            }
        ) => {
            (async () => {
                await sendPasswordResetMail(values.email);
                setSubmitting(false);
                emailSentCallback();
            })();
        }}
    >
        {({
            isSubmitting,
            values,
        }) => {
            return (
                <main className="grid-2--mobile grid-8--tablet grid-12--desktop">
                    <Form>
                        <EmailInput
                            label="E-Mail"
                            value={values.email}
                            name="email"
                        />
                        <FormSubmit
                            disabled={isSubmitting}
                            label="Link anfordern"
                        />
                        <CustomButton
                            label="Abbrechen"
                            onClick={() => {
                                setShowForgotPassword(false);
                            }}
                        />
                        <br/>
                        <br/>
                    </Form>
                </main>
            );
        }}
    </Formik>;
};

export default PasswordReset;
