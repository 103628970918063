import * as React from 'react';
import {
    useState
} from 'react';
import PasswordReset from '../../../components/forms/PasswordReset/PasswordReset';
import DefaultPage from '../../../components/layouts/DefaultPage';
import UnauthenticatedContent from '../../../components/UnauthenticatedContent/UnauthenticatedContent';



// markup
const PasswordResetPage = () => {
    const [emailSent, setEmailSent,] = useState(false);


    if (emailSent) {
        return <main>
            If we know your email you have received a mail with instructions
        </main>;
    }

    return (
        <DefaultPage>
            <UnauthenticatedContent/>
            <PasswordReset emailSentCallback={() => {
                setEmailSent(true);
            }}/>
        </DefaultPage>
    );
};

export default PasswordResetPage;
